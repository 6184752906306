import React from 'react';
import { graphql } from 'gatsby';
import SanityBlockContent from '@sanity/block-content-to-react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import Head from '../components/head';
import Layout from '../components/layout';

const sanityConfig = {
  projectId: '80jisvar',
  dataset: 'production',
};

const serializers = {
  types: {
    imgBlock: ({ node }) => {
      const gatsbyImageData = getGatsbyImageData(node.asset._id, {}, sanityConfig);
      return <GatsbyImage image={gatsbyImageData} alt="" placeholder="dominantColor" className="mb-6" />;
    },
  },
};

export default function SinglePage({ data: { page } }) {
  return (
    <Layout>
      <Head title={page.headline} />
      <div className="max-w-screen-lg m-auto sm:animate-fade-content-page">
        <h1>
          <span aria-hidden="true" />
          {page.headline}
        </h1>
        <SanityBlockContent
          renderContainerOnSingleChild
          blocks={page._rawContent}
          serializers={serializers}
          className="intro sm:text-lg mb-12 sm:mb-20"
        />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    page: sanityPage(id: { eq: "-c6fece5b-5a9f-523d-b284-44244ceece2d" }) {
      _rawContent(resolveReferences: { maxDepth: 10 })
      headline
    }
  }
`;
